import React from "react";
import { useTranslation } from "react-i18next";

const Translator = ({ textKey, className }) => {
  // const { textKey } = props;
  const { t } = useTranslation();
  const text = t(textKey);

  if (className) return <span className={className}>{text}</span>
  return text
};

export default Translator;

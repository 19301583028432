import React, { Fragment } from "react"
import { Row, Col } from "antd"
import { FacebookFilled, LinkedinFilled, TwitterSquareFilled, ArrowUpOutlined } from "@ant-design/icons"
import { Link } from "react-router-dom";
import Logo from "../../assets/lg.png";
// import App from "../../assets/app.png";
import Log from "../../assets/gg.png";
// import Qr from "../../assets/qr.webp";
import Translator from "views/app/shared-components/translator";
import "./style.css";



const Footer = () => {

    const scrollToDiv = () => {
        document.getElementById('top-head').scrollIntoView({ behavior: 'smooth', block: "start" });
    }

    return (
        <Fragment>
            <Row gutter={[16, 16]} className="footer">
                <Col md={2} xs={24} />
                <Col md={4} xs={24}>
                    <Link to="/home">
                        <img src={Logo} alt="..." width="100px" height="auto" />
                    </Link>
                    <br></br>
                    <h6 ><Translator textKey="footer.follow" /></h6>
                    <a href="https://www.facebook.com/ZunoCabs" target="_">
                        <FacebookFilled />
                    </a>
                    <a href="https://www.linkedin.com/company/zunocabs/" target="_">
                        <LinkedinFilled />
                    </a>
                    <a href="https://twitter.com/ZunoCabs" target="_">
                        <TwitterSquareFilled />
                    </a>
                </Col>
                <Col md={4} xs={12}>
                    <ul>
                        <li><Link to="/home"><Translator textKey="footer.home" /></Link></li>
                        <li><Link to="/about"><Translator textKey="footer.about" /></Link></li>
                        {/* <li><Link to="/driver"><Translator textKey="footer.zunodriver" /></Link></li> */}
                        <li><Link to="/careers"><Translator textKey="footer.careers" /></Link></li>
                        <li><Link to="/contact"><Translator textKey="footer.contact" /></Link></li>
                    </ul>
                </Col>
                <Col md={4} xs={12}>
                    <ul>
                        <li><Link to="/faq"><Translator textKey="footer.faq" /></Link></li>
                        <li><Link to="/privacy_policy"><Translator textKey="footer.privacy" /></Link></li>
                        <li><Link to="/customer_policy"><Translator textKey="footer.cust" /></Link></li>
                        <li><Link to="/captain_policy"><Translator textKey="footer.captain" /></Link></li>
                    </ul>
                </Col>
                <Col md={4} xs={24}>
                    <h1><Translator textKey="footer.one" /></h1>
                    <Row>
                        <Col md={15} xs={12}>
                            <a href="https://play.google.com/store/apps/details?id=com.corpfield.zunocabs_user" target="_">
                                <img src={Log} alt="..." className="play-store" width="auto" height="auto" />
                            </a>
                        </Col>
                        <Col md={9} />
                        <Col md={15} xs={12}>
                            {/* <img src={App} alt="..." className="play-stores" width="auto" height="auto" /> */}
                        </Col>
                        <Col md={9} />
                    </Row>
                </Col>
                <Col md={4} xs={24}>
                    <h1><Translator textKey="footer.two" /></h1>
                    <Row>
                        <Col md={15} xs={12}>
                            <a href="https://play.google.com/store/apps/details?id=com.corpfield.zunocabs_driver" target="_">
                                <img src={Log} alt="..." className="play-store" width="auto" height="auto" />
                            </a>
                        </Col>
                        <Col md={9} />
                        <Col md={12} xs={13}>
                            {/* <img src={Qr} alt="..." className="qr" width="auto" height="auto" /> */}
                        </Col>
                        <Col md={12} />
                    </Row>
                </Col>
                <Col md={2} xs={24} className="scroll">
                    <button onClick={scrollToDiv} className="ar-g"><ArrowUpOutlined /></button>
                </Col>
            </Row >
            <Row gutter={[16, 16]} className="mini-footer txt-center">
                <Col md={24} className="txt-center"><p>Copyright © 2022 <a href="https://www.corpfield.com/" target="_"> Corpfield Technology Solutions</a> . All rights reserved. </p></Col>
            </Row>
        </Fragment >
    )
}

export default Footer
import React, { Fragment, useState } from "react"
import { Row, Col, Button, Drawer } from "antd"
import { NavLink, Link } from "react-router-dom";
import Translator from "views/app/shared-components/translator";
// import LanguageSwitch from "views/app/shared-components/LanguageSwitch";
import Download from "../../assets/download.webp";
import Logo from "../../assets/lk.png";
import PopUp from "views/app/shared-components/popup";
import "./style.css";

const Header = () => {
    const [visible, setVisible] = useState(false);

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };


    return (
        <Fragment>
            <Row className="navbar-head" id="top-head">
                <Col md={3} xs={0}></Col>
                <Col md={4} xs={12} className="txt-left">
                    <Link to="/">
                        <img src={Logo} alt="..." className="logo" width="auto" height="auto" />
                    </Link>
                </Col>
                <Col md={15} xs={0} >
                    <ul>
                        <li><NavLink to="/home"><Translator textKey="Header.home" /></NavLink></li>
                        <li><NavLink to="/about"><Translator textKey="Header.about" /></NavLink></li>
                        <li><NavLink to="/driver"><Translator textKey="Header.driver" /></NavLink></li>
                        <li><NavLink to="/live-tracking"><Translator textKey="Header.live-tracking" /></NavLink></li>
                        <li><NavLink to="/contact"><Translator textKey="Header.contact" /></NavLink></li>
                        {/* <li><LanguageSwitch /></li> */}
                        <li> <PopUp name={<Translator textKey="Header.download" />} /></li>
                    </ul>
                </Col>
                <Col md={2} xs={0}></Col>
                <Col md={0} xs={7} className="txt-right mob-download">
                    <PopUp name={<Translator textKey="Header.download" />} />
                </Col>
                <Col md={0} xs={5} className="txt-right mobile-head">
                    <Button type="primary" onClick={showDrawer}>
                        <img src={Download} width="auto" height="auto" alt="..." />
                    </Button>
                    <Drawer title="Basic Drawer" placement="top" onClose={onClose} visible={visible}>
                        <img src={Logo} alt="..." className="mob-logo" />
                        <ul className="mob-nav" >
                            <li><NavLink onClick={onClose} to="/home"><Translator textKey="Header.home" /></NavLink></li>
                            <li><NavLink onClick={onClose} to="/about"><Translator textKey="Header.about" /></NavLink></li>
                            <li><NavLink onClick={onClose} to="/driver"><Translator textKey="Header.driver" /></NavLink></li>
                            <li><NavLink to="/live-tracking"><Translator textKey="Header.live-tracking" /></NavLink></li>
                            <li><NavLink onClick={onClose} to="/privacy_policy"><Translator textKey="footer.privacy" /></NavLink></li>
                            <li><NavLink onClick={onClose} to="/customer_policy"><Translator textKey="footer.cust" /></NavLink></li>
                            <li><NavLink onClick={onClose} to="/captain_policy"><Translator textKey="footer.captain" /></NavLink></li>
                            <li><NavLink onClick={onClose} to="/contact"><Translator textKey="Header.contact" /></NavLink></li>
                            {/* <li><LanguageSwitch /></li> */}
                        </ul>
                    </Drawer>
                </Col>
            </Row>
        </Fragment>
    )
}

export default Header
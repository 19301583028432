import React, { useState, Fragment } from "react"
import { Row, Col, Modal } from "antd"
// import Andriod from "../../../assets/lk.png";
// import Ios from "../../../assets/logo.png";
// import App from "../../../assets/app.png";
import Log from "../../../assets/gg.png";


const PopUp = (props) => {
    const { name } = props;

    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    }

    return (
        <Fragment>
            <div className="close">
                <button onClick={showModal}>{name}</button>
                <Modal title="Basic Modal" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} width={800} className="download">
                    <Row className="popup">
                        <Col md={10} xs={24}>
                            <div className="txt-center">
                                <h1>User App Download</h1>
                                <a href="https://play.google.com/store/apps/details?id=com.corpfield.zunocabs_user" target="_">
                                    <img loading="lazy" src={Log} alt="..." width="170px" height="auto" />
                                </a>
                                <h3>Android</h3>
                            </div>
                        </Col>
                        <Col md={14} xs={24}>
                            <div className="txt-center">
                                <h1>Captain App Download</h1>
                                <a href="https://play.google.com/store/apps/details?id=com.corpfield.zunocabs_driver" target="_">
                                    <img loading="lazy" src={Log} alt="..." width="170px" height="auto" />
                                </a>
                                <h3>Android</h3>
                            </div>
                        </Col>
                    </Row>
                </Modal>
            </div>
        </Fragment>
    )
}

export default PopUp
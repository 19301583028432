import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

export const AppViews = () => {

    return (
        <Suspense fallback={""}>
            <Switch>
                <Route exact path="/" component={lazy(() => import(`./home`))} />
                <Route path="/home" component={lazy(() => import(`./home`))} />
                <Route path="/about" component={lazy(() => import(`./about`))} />
                <Route path="/driver" component={lazy(() => import(`./driver`))} />
                <Route path="/live-tracking" component={lazy(() => import(`./live-tracking`))} />
                <Route path="/careers" component={lazy(() => import(`./carreers`))} />
                <Route path="/contact" component={lazy(() => import(`./contact`))} />
                <Route path="/privacy_policy" component={lazy(() => import(`./policy/privacy-policy`))} />
                <Route path="/customer_policy" component={lazy(() => import(`./policy/customer-policy`))} />
                <Route path="/captain_policy" component={lazy(() => import(`./policy/captain-policy`))} />
                <Route path="/faq" component={lazy(() => import(`./policy/faq`))} />
                <Redirect to="/" />
            </Switch>
        </Suspense>
    )
}

export default AppViews;


import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route, useHistory } from "react-router-dom";
import Views from "views";
import "./App.css";
import "./i18n.js";

function App() {
    let history = useHistory();
    return (
        <Suspense fallback={() => <p></p>}>
            <Router history={history}>
                <Switch>
                    <Route path="/" component={Views} />
                </Switch>
            </Router>
        </Suspense>
    );
}

export default App;

import React from "react";
// import Cookies from "js-cookie";
import AppLayout from "layouts/app-layout";
// import { useStore } from "store";
import { Switch, Route } from "react-router-dom";

// function AppInterceptor({ children, isAuthenticated, prefix, ...rest }) {
//     return (
//         <Route
//             {...rest}
//             render={({ location }) => isAuthenticated ? (
//                 children
//             ) : (
//                 <Redirect to={{ pathname: prefix, state: { from: location } }} />
//             )
//             }
//         />
//     );
// }

export const Views = (props) => {
    const { location } = props

    return (
        <Switch>
            <Route exact path="">
                <AppLayout location={location} />
            </Route>
            {/*<AppInterceptor 
                path="/">
                isAuthenticated={Cookies.get("m_token")} 
                <AppLayout location={location}/>
            </AppInterceptor>*/}
        </Switch>
    )
}

export default Views;